
.membershipcard{
    border: 1px solid #ccc;
    width: 45%;
    padding: 10px;
    border-radius: 15px;
}
.membershipcard h2{
    margin-bottom: 0;
}
.membershipcard .ant-radio-wrapper{
    width: 100%;
    height: 100%;
}
.membershipcard.active{
    background-color: #ff0057;
}
.ms-radio-group{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
th.ant-table-cell{
    color: #fff !important;
}
.ant-btn.ant-btn-sm{
 height: auto; 
}