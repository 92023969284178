
  .full-width-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

.split-container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.column {
    flex: 1;
    justify-content: inherit;
    align-items: center;
    font-size: 1 rem;
    border-radius: 5px;
}

#column1 {
    flex: 0.8; /* 60% width for Column 1 */
}


#column2 {
    margin-left: 5px;
    flex: 0.2; /* 40% width for Column 2 */
}

.row {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1 rem;
  border-radius: 20px;
  color: white;
}
.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  width:inherit; /* You can adjust the width as needed */
  justify-content: space-between;
}

.ant-btn-primary {
  /* box-sizing: border-box; */
  /* width: 150px ; */
  
}
.notes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: inherit; /* You can adjust the width as needed */
  font-size: 1 rem;
}
.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
